/* eslint-disable no-restricted-globals */
/* eslint-disable radix */
/* eslint-disable react/button-has-type */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable max-len */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable react/jsx-props-no-spreading */
import {
    useEffect, useState, useContext, useCallback,
} from 'react';
import axios from 'axios';
import { Link } from 'gatsby';
import classNames from 'classnames';
import { FormattedMessage, defineMessages } from 'react-intl';
import InputRange from 'react-input-range';
import { Context } from '../../store/Provider';
import OrderSummary from '../../components/OrderSummary/OrderSummary';
import Seo from '../../components/Seo/Seo';
import Post from '../../components/Post/Post';
import 'react-input-range/lib/css/index.css';
import * as styles from './OrderPage.module.scss';
import { getServiceName } from '../../helpers/helpers';
import tickImgPath from './img/tick.svg';
import paypalImgPath from './img/paypal_logo.png';
import stripeImgPath from './img/stripe.svg';
import ProductLogo from '../../components/ProductLogo/ProductLogo';
import ChangeMediaModal from '../../components/Modal/ChangeMediaModal/ChangeMediaModal';

const messages = defineMessages({
    title: {
        id: 'orderPage.postTitle',
        defaultMessage: 'Important information',
    },
    importantTIKTOK: {
        id: 'productPage.importantTIKTOK',
        defaultMessage:
			'importantTIKTOKLorem ipsum dolor sit amet, consectetur adipiscing elit. Proin aliquet sapien ut gravida volutpat. Pellentesque sed risus dui. Cras nec massa libero. Proin ut felis enim. Duis elementum tortor pellentesque vehicula sollicitudin. Praesent et consectetur est. Donec suscipit velit aliquet, congue massa vitae, ornare justo. Interdum et malesuada fames ac ante ipsum primis in faucibus. Fusce augue risus, rutrum a blandit a, fringilla ac erat. Nullam ac diam ut ipsum hendrerit ultricies vel ac purus. Suspendisse vel magna id nibh placerat fringilla vitae vel neque. Mauris rhoncus finibus erat. Curabitur tincidunt ante odio, eget sollicitudin mi sollicitudin non. {br}{br}Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin aliquet sapien ut gravida volutpat. Pellentesque sed risus dui. Cras nec massa libero. Proin ut felis enim. Duis elementum tortor pellentesque vehicula sollicitudin. Praesent et consectetur est. Donec suscipit velit aliquet, congue massa vitae, ornare justo. Interdum et malesuada fames ac ante ipsum primis in faucibus. Fusce augue risus, rutrum a blandit a, fringilla ac erat. Nullam ac diam ut ipsum hendrerit ultricies vel ac purus. Suspendisse vel magna id nibh placerat fringilla vitae vel neque. Mauris rhoncus finibus erat. Curabitur tincidunt ante odio, eget sollicitudin mi sollicitudin non.',
    },
    importantINSTAGRAM: {
        id: 'productPage.importantINSTAGRAM',
        defaultMessage:
			'importantINSTAGRAMLorem ipsum dolor sit amet, consectetur adipiscing elit. Proin aliquet sapien ut gravida volutpat. Pellentesque sed risus dui. Cras nec massa libero. Proin ut felis enim. Duis elementum tortor pellentesque vehicula sollicitudin. Praesent et consectetur est. Donec suscipit velit aliquet, congue massa vitae, ornare justo. Interdum et malesuada fames ac ante ipsum primis in faucibus. Fusce augue risus, rutrum a blandit a, fringilla ac erat. Nullam ac diam ut ipsum hendrerit ultricies vel ac purus. Suspendisse vel magna id nibh placerat fringilla vitae vel neque. Mauris rhoncus finibus erat. Curabitur tincidunt ante odio, eget sollicitudin mi sollicitudin non. {br}{br}Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin aliquet sapien ut gravida volutpat. Pellentesque sed risus dui. Cras nec massa libero. Proin ut felis enim. Duis elementum tortor pellentesque vehicula sollicitudin. Praesent et consectetur est. Donec suscipit velit aliquet, congue massa vitae, ornare justo. Interdum et malesuada fames ac ante ipsum primis in faucibus. Fusce augue risus, rutrum a blandit a, fringilla ac erat. Nullam ac diam ut ipsum hendrerit ultricies vel ac purus. Suspendisse vel magna id nibh placerat fringilla vitae vel neque. Mauris rhoncus finibus erat. Curabitur tincidunt ante odio, eget sollicitudin mi sollicitudin non.',
    },
    importantPINTEREST: {
        id: 'productPage.importantPINTEREST',
        defaultMessage:
			'importantPINTERESTLorem ipsum dolor sit amet, consectetur adipiscing elit. Proin aliquet sapien ut gravida volutpat. Pellentesque sed risus dui. Cras nec massa libero. Proin ut felis enim. Duis elementum tortor pellentesque vehicula sollicitudin. Praesent et consectetur est. Donec suscipit velit aliquet, congue massa vitae, ornare justo. Interdum et malesuada fames ac ante ipsum primis in faucibus. Fusce augue risus, rutrum a blandit a, fringilla ac erat. Nullam ac diam ut ipsum hendrerit ultricies vel ac purus. Suspendisse vel magna id nibh placerat fringilla vitae vel neque. Mauris rhoncus finibus erat. Curabitur tincidunt ante odio, eget sollicitudin mi sollicitudin non. {br}{br}Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin aliquet sapien ut gravida volutpat. Pellentesque sed risus dui. Cras nec massa libero. Proin ut felis enim. Duis elementum tortor pellentesque vehicula sollicitudin. Praesent et consectetur est. Donec suscipit velit aliquet, congue massa vitae, ornare justo. Interdum et malesuada fames ac ante ipsum primis in faucibus. Fusce augue risus, rutrum a blandit a, fringilla ac erat. Nullam ac diam ut ipsum hendrerit ultricies vel ac purus. Suspendisse vel magna id nibh placerat fringilla vitae vel neque. Mauris rhoncus finibus erat. Curabitur tincidunt ante odio, eget sollicitudin mi sollicitudin non.',
    },
    importantSPOTIFY: {
        id: 'productPage.importantSPOTIFY',
        defaultMessage:
			'importantSPOTIFYLorem ipsum dolor sit amet, consectetur adipiscing elit. Proin aliquet sapien ut gravida volutpat. Pellentesque sed risus dui. Cras nec massa libero. Proin ut felis enim. Duis elementum tortor pellentesque vehicula sollicitudin. Praesent et consectetur est. Donec suscipit velit aliquet, congue massa vitae, ornare justo. Interdum et malesuada fames ac ante ipsum primis in faucibus. Fusce augue risus, rutrum a blandit a, fringilla ac erat. Nullam ac diam ut ipsum hendrerit ultricies vel ac purus. Suspendisse vel magna id nibh placerat fringilla vitae vel neque. Mauris rhoncus finibus erat. Curabitur tincidunt ante odio, eget sollicitudin mi sollicitudin non. {br}{br}Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin aliquet sapien ut gravida volutpat. Pellentesque sed risus dui. Cras nec massa libero. Proin ut felis enim. Duis elementum tortor pellentesque vehicula sollicitudin. Praesent et consectetur est. Donec suscipit velit aliquet, congue massa vitae, ornare justo. Interdum et malesuada fames ac ante ipsum primis in faucibus. Fusce augue risus, rutrum a blandit a, fringilla ac erat. Nullam ac diam ut ipsum hendrerit ultricies vel ac purus. Suspendisse vel magna id nibh placerat fringilla vitae vel neque. Mauris rhoncus finibus erat. Curabitur tincidunt ante odio, eget sollicitudin mi sollicitudin non.',
    },
    importantTUMBLR: {
        id: 'productPage.importantTUMBLR',
        defaultMessage:
			'importantTUMBLRLorem ipsum dolor sit amet, consectetur adipiscing elit. Proin aliquet sapien ut gravida volutpat. Pellentesque sed risus dui. Cras nec massa libero. Proin ut felis enim. Duis elementum tortor pellentesque vehicula sollicitudin. Praesent et consectetur est. Donec suscipit velit aliquet, congue massa vitae, ornare justo. Interdum et malesuada fames ac ante ipsum primis in faucibus. Fusce augue risus, rutrum a blandit a, fringilla ac erat. Nullam ac diam ut ipsum hendrerit ultricies vel ac purus. Suspendisse vel magna id nibh placerat fringilla vitae vel neque. Mauris rhoncus finibus erat. Curabitur tincidunt ante odio, eget sollicitudin mi sollicitudin non. {br}{br}Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin aliquet sapien ut gravida volutpat. Pellentesque sed risus dui. Cras nec massa libero. Proin ut felis enim. Duis elementum tortor pellentesque vehicula sollicitudin. Praesent et consectetur est. Donec suscipit velit aliquet, congue massa vitae, ornare justo. Interdum et malesuada fames ac ante ipsum primis in faucibus. Fusce augue risus, rutrum a blandit a, fringilla ac erat. Nullam ac diam ut ipsum hendrerit ultricies vel ac purus. Suspendisse vel magna id nibh placerat fringilla vitae vel neque. Mauris rhoncus finibus erat. Curabitur tincidunt ante odio, eget sollicitudin mi sollicitudin non.',
    },
    importantTWITCH: {
        id: 'productPage.importantTWITCH',
        defaultMessage:
			'importantTWITCHLorem ipsum dolor sit amet, consectetur adipiscing elit. Proin aliquet sapien ut gravida volutpat. Pellentesque sed risus dui. Cras nec massa libero. Proin ut felis enim. Duis elementum tortor pellentesque vehicula sollicitudin. Praesent et consectetur est. Donec suscipit velit aliquet, congue massa vitae, ornare justo. Interdum et malesuada fames ac ante ipsum primis in faucibus. Fusce augue risus, rutrum a blandit a, fringilla ac erat. Nullam ac diam ut ipsum hendrerit ultricies vel ac purus. Suspendisse vel magna id nibh placerat fringilla vitae vel neque. Mauris rhoncus finibus erat. Curabitur tincidunt ante odio, eget sollicitudin mi sollicitudin non. {br}{br}Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin aliquet sapien ut gravida volutpat. Pellentesque sed risus dui. Cras nec massa libero. Proin ut felis enim. Duis elementum tortor pellentesque vehicula sollicitudin. Praesent et consectetur est. Donec suscipit velit aliquet, congue massa vitae, ornare justo. Interdum et malesuada fames ac ante ipsum primis in faucibus. Fusce augue risus, rutrum a blandit a, fringilla ac erat. Nullam ac diam ut ipsum hendrerit ultricies vel ac purus. Suspendisse vel magna id nibh placerat fringilla vitae vel neque. Mauris rhoncus finibus erat. Curabitur tincidunt ante odio, eget sollicitudin mi sollicitudin non.',
    },
    importantLINKEDIN: {
        id: 'productPage.importantLINKEDIN',
        defaultMessage:
			'importantLINKEDINLorem ipsum dolor sit amet, consectetur adipiscing elit. Proin aliquet sapien ut gravida volutpat. Pellentesque sed risus dui. Cras nec massa libero. Proin ut felis enim. Duis elementum tortor pellentesque vehicula sollicitudin. Praesent et consectetur est. Donec suscipit velit aliquet, congue massa vitae, ornare justo. Interdum et malesuada fames ac ante ipsum primis in faucibus. Fusce augue risus, rutrum a blandit a, fringilla ac erat. Nullam ac diam ut ipsum hendrerit ultricies vel ac purus. Suspendisse vel magna id nibh placerat fringilla vitae vel neque. Mauris rhoncus finibus erat. Curabitur tincidunt ante odio, eget sollicitudin mi sollicitudin non. {br}{br}Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin aliquet sapien ut gravida volutpat. Pellentesque sed risus dui. Cras nec massa libero. Proin ut felis enim. Duis elementum tortor pellentesque vehicula sollicitudin. Praesent et consectetur est. Donec suscipit velit aliquet, congue massa vitae, ornare justo. Interdum et malesuada fames ac ante ipsum primis in faucibus. Fusce augue risus, rutrum a blandit a, fringilla ac erat. Nullam ac diam ut ipsum hendrerit ultricies vel ac purus. Suspendisse vel magna id nibh placerat fringilla vitae vel neque. Mauris rhoncus finibus erat. Curabitur tincidunt ante odio, eget sollicitudin mi sollicitudin non.',
    },
    importantYOUTUBE: {
        id: 'productPage.importantYOUTUBE',
        defaultMessage:
			'importantYOUTUBELorem ipsum dolor sit amet, consectetur adipiscing elit. Proin aliquet sapien ut gravida volutpat. Pellentesque sed risus dui. Cras nec massa libero. Proin ut felis enim. Duis elementum tortor pellentesque vehicula sollicitudin. Praesent et consectetur est. Donec suscipit velit aliquet, congue massa vitae, ornare justo. Interdum et malesuada fames ac ante ipsum primis in faucibus. Fusce augue risus, rutrum a blandit a, fringilla ac erat. Nullam ac diam ut ipsum hendrerit ultricies vel ac purus. Suspendisse vel magna id nibh placerat fringilla vitae vel neque. Mauris rhoncus finibus erat. Curabitur tincidunt ante odio, eget sollicitudin mi sollicitudin non. {br}{br}Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin aliquet sapien ut gravida volutpat. Pellentesque sed risus dui. Cras nec massa libero. Proin ut felis enim. Duis elementum tortor pellentesque vehicula sollicitudin. Praesent et consectetur est. Donec suscipit velit aliquet, congue massa vitae, ornare justo. Interdum et malesuada fames ac ante ipsum primis in faucibus. Fusce augue risus, rutrum a blandit a, fringilla ac erat. Nullam ac diam ut ipsum hendrerit ultricies vel ac purus. Suspendisse vel magna id nibh placerat fringilla vitae vel neque. Mauris rhoncus finibus erat. Curabitur tincidunt ante odio, eget sollicitudin mi sollicitudin non.',
    },
    importantFACEBOOK: {
        id: 'productPage.importantFACEBOOK',
        defaultMessage:
			'importantFACEBOOKLorem ipsum dolor sit amet, consectetur adipiscing elit. Proin aliquet sapien ut gravida volutpat. Pellentesque sed risus dui. Cras nec massa libero. Proin ut felis enim. Duis elementum tortor pellentesque vehicula sollicitudin. Praesent et consectetur est. Donec suscipit velit aliquet, congue massa vitae, ornare justo. Interdum et malesuada fames ac ante ipsum primis in faucibus. Fusce augue risus, rutrum a blandit a, fringilla ac erat. Nullam ac diam ut ipsum hendrerit ultricies vel ac purus. Suspendisse vel magna id nibh placerat fringilla vitae vel neque. Mauris rhoncus finibus erat. Curabitur tincidunt ante odio, eget sollicitudin mi sollicitudin non. {br}{br}Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin aliquet sapien ut gravida volutpat. Pellentesque sed risus dui. Cras nec massa libero. Proin ut felis enim. Duis elementum tortor pellentesque vehicula sollicitudin. Praesent et consectetur est. Donec suscipit velit aliquet, congue massa vitae, ornare justo. Interdum et malesuada fames ac ante ipsum primis in faucibus. Fusce augue risus, rutrum a blandit a, fringilla ac erat. Nullam ac diam ut ipsum hendrerit ultricies vel ac purus. Suspendisse vel magna id nibh placerat fringilla vitae vel neque. Mauris rhoncus finibus erat. Curabitur tincidunt ante odio, eget sollicitudin mi sollicitudin non.',
    },
    importantTWITTER: {
        id: 'productPage.importantTWITTER',
        defaultMessage:
			'importantTWITTERLorem ipsum dolor sit amet, consectetur adipiscing elit. Proin aliquet sapien ut gravida volutpat. Pellentesque sed risus dui. Cras nec massa libero. Proin ut felis enim. Duis elementum tortor pellentesque vehicula sollicitudin. Praesent et consectetur est. Donec suscipit velit aliquet, congue massa vitae, ornare justo. Interdum et malesuada fames ac ante ipsum primis in faucibus. Fusce augue risus, rutrum a blandit a, fringilla ac erat. Nullam ac diam ut ipsum hendrerit ultricies vel ac purus. Suspendisse vel magna id nibh placerat fringilla vitae vel neque. Mauris rhoncus finibus erat. Curabitur tincidunt ante odio, eget sollicitudin mi sollicitudin non. {br}{br}Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin aliquet sapien ut gravida volutpat. Pellentesque sed risus dui. Cras nec massa libero. Proin ut felis enim. Duis elementum tortor pellentesque vehicula sollicitudin. Praesent et consectetur est. Donec suscipit velit aliquet, congue massa vitae, ornare justo. Interdum et malesuada fames ac ante ipsum primis in faucibus. Fusce augue risus, rutrum a blandit a, fringilla ac erat. Nullam ac diam ut ipsum hendrerit ultricies vel ac purus. Suspendisse vel magna id nibh placerat fringilla vitae vel neque. Mauris rhoncus finibus erat. Curabitur tincidunt ante odio, eget sollicitudin mi sollicitudin non.',
    },
    metaTitle: {
        id: 'productPage.metaTitle',
        defaultMessage: '{product} {service} - Cheap social media services | Cheap-Social.com',
    },
    metaDesc: {
        id: 'productPage.metaDescription',
        defaultMessage:
			'{product} {service}, buy {product} {service}, gain {product} {service}, get more {product} {service}, buy real {product} {service}, buy {product} {service} cheap, real {product} {service}, buy {product} {service} cheap, how to buy {product} {service}, increase {product} {service}',
    },
});

const OrderPage = ({
    serverData, name, service, id,
}) => {
    const { dispatch } = useContext(Context);
    const [serviceData, setServiceData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [form, setForm] = useState({
        email: '',
        url: '',
        discount: '',
    });
    const [formError, setFormError] = useState({
        email: null,
        url: null,
        discount: null,
    });
    const [isConfirm, setIsConfirm] = useState(false);
    const [isApplied, setIsApplied] = useState(false);
    const [radio, setRadio] = useState(null);
    const [terms, setTerms] = useState(false);
    const [marketing, setMarketing] = useState(false);
    const [rangeValue, setRangeValue] = useState(0);
    const [price, setPrice] = useState(0);
    const [payment, setPayment] = useState(process.env.GATSBY_PAYPAL === 'false' ? 'STRIPE' : 'PAYPAL');

    const handleChange = useCallback(
        (e) => {
            const { target } = e;
            const { name: inputName, value } = target;
            setFormError({
                ...formError,
                [inputName]: null,
            });
            setForm({
                ...form,
                [inputName]: value,
            });
        },
        [form, formError],
    );

    const handleSubmit = (e) => {
        e.preventDefault();
        setIsConfirm(true);
    };

    const calc = () => {
        axios
            .post(`${process.env.GATSBY_API_URL}/api/order/calculate`, {
                serviceId: serviceData.find((o) => o.id === radio)?.id,
                quantity: rangeValue,
                discountCode: form.discount,
            })
            .then(({ data }) => {
                const { discountApplied, price: priceRes } = data;
                setIsApplied(discountApplied);
                setPrice(parseFloat(priceRes));
            })
            .catch((err) => console.log(err));
    };

    const handlePost = (e) => {
        e.preventDefault();
        const { email, url, discount } = form;
        let errors = {};
        if (!email) {
            errors = {
                ...errors,
                email: (
                    <FormattedMessage
                        id="orderPage.requiredField"
                        defaultMessage="Required field"
                    />
                ),
            };
        }
        if (!url) {
            errors = {
                ...errors,
                url: (
                    <FormattedMessage
                        id="orderPage.requiredField"
                        defaultMessage="Required field"
                    />
                ),
            };
        }
        if (!email || !url) return setFormError(errors);
        setIsLoading(true);
        return axios
            .post(`${process.env.GATSBY_API_URL}/api/order`, {
                serviceId: serviceData.find((o) => o.id === radio)?.id,
                quantity: rangeValue,
                email,
                url,
                discountCode: discount,
                paymentMethod: payment,
            })
            .then(({ data }) => {
                const { redirectUrl } = data;
                if (redirectUrl) window.location.assign(redirectUrl);
            })
            .catch((err) => {
                setIsLoading(false);
                console.log(err);
            });
    };

    useEffect(() => {
        if (name && service && id) {
            setServiceData(serverData);
            setRadio(parseInt(id));
        }
    }, [serverData]);

    useEffect(() => {
        setRangeValue(
            serviceData.find((o) => o.id === radio)?.minRange
				+ serviceData.find((o) => o.id === radio)?.minRange * 1,
        );
        setPrice((rangeValue / 1000) * serviceData.find((o) => o.id === radio)?.price);
    }, [radio]);

    useEffect(() => {
        setPrice((rangeValue / 1000) * serviceData.find((o) => o.id === radio)?.price);
    }, [rangeValue, name, service]);

    return (
        <>
            <Seo
                title={messages.metaTitle}
                titleValues={{ product: getServiceName(name), service }}
                description={messages.metaDesc}
                descriptionValues={{ product: getServiceName(name), service }}
            />
            <div className={styles.productHead}>
                <div className={styles.container}>
                    {isConfirm ? (
                        <FormattedMessage
                            id="orderPage.confirmDetailsTitle"
                            defaultMessage="2. Confirm details"
                        />
                    ) : (
                        <FormattedMessage
                            id="orderPage.orderInfoTitle"
                            defaultMessage="1. Order information"
                        />
                    )}
                </div>
            </div>
            <div className={styles.container}>
                {isConfirm ? (
                    <div className={styles.grid}>
                        <div>
                            <OrderSummary
                                name={name}
                                serviceName={
                                    serverData.filter(({ id: productId }) => productId === radio)[0]
                                        ?.name
                                }
                                amount={rangeValue}
                                price={price}
                            />
                        </div>
                        <div>
                            <form onSubmit={handlePost} className={styles.form}>
                                <h3 className={styles.formTitle}>
                                    <span>
                                        <FormattedMessage
                                            id="orderPage.finalStep"
                                            defaultMessage="Final step:"
                                        />
                                    </span>
                                    {' '}
                                    <FormattedMessage
                                        id="orderPage.confirmInfoRecipient"
                                        defaultMessage="confirm recipient information"
                                    />
                                </h3>
                                <div
                                    className={classNames(styles.inputGroup, {
                                        [styles.errorInput]: formError.email,
                                    })}
                                >
                                    <label htmlFor="email">
                                        <FormattedMessage
                                            id="orderPage.email"
                                            defaultMessage="Email address"
                                        />
                                    </label>
                                    <input
                                        type="email"
                                        id="email"
                                        name="email"
                                        onChange={handleChange}
                                        value={form.email}
                                        placeholder="example@gmail.com"
                                    />
                                    {formError.email && (
                                        <div className={styles.error}>{formError.email}</div>
                                    )}
                                </div>
                                <div
                                    className={classNames(styles.inputGroup, {
                                        [styles.errorInput]: formError.url,
                                    })}
                                >
                                    <label htmlFor="url">
                                        <FormattedMessage
                                            id="orderPage.socialUrl"
                                            defaultMessage="Social page URL"
                                        />
                                    </label>
                                    <input
                                        type="text"
                                        id="url"
                                        name="url"
                                        onChange={handleChange}
                                        value={form.url}
                                        placeholder="facebook.com/url"
                                    />
                                    {formError.url && (
                                        <div className={styles.error}>{formError.url}</div>
                                    )}
                                </div>
                                <div
                                    className={classNames(styles.inputGroup, {
                                        [styles.errorInput]: formError.discount,
                                    })}
                                >
                                    <label htmlFor="discount">
                                        <FormattedMessage
                                            id="orderPage.discountCode"
                                            defaultMessage="Discount code"
                                        />
                                    </label>
                                    <input
                                        type="text"
                                        id="discount"
                                        placeholder="ASFASF"
                                        value={form.discount}
                                        name="discount"
                                        onChange={handleChange}
                                    />
                                    {form.discount && (
                                        <div className={styles.apply} onClick={() => calc()}>
                                            <FormattedMessage
                                                id="orderPage.apply"
                                                defaultMessage="Apply"
                                            />
                                        </div>
                                    )}
                                </div>
                                {isApplied && (
                                    <div className={styles.discount}>
                                        <img src={tickImgPath} alt="Tick" className={styles.icon} />
                                        <FormattedMessage
                                            id="orderPage.discountApplied"
                                            defaultMessage="Discount applied"
                                        />
                                    </div>
                                )}
                                <label htmlFor="terms" className={styles.checkbox}>
                                    <input
                                        id="terms"
                                        checked={terms}
                                        className={styles.checkboxInput}
                                        onChange={() => setTerms(!terms)}
                                        type="checkbox"
                                    />
                                    <span className={styles.checkmark} />
                                    <span>
                                        <FormattedMessage
                                            id="orderPage.terms"
                                            defaultMessage="I agree with <link>Terms of Services</link>"
                                            values={{ link: (text) => <Link to="/terms-of-service">{text}</Link> }}
                                        />
                                    </span>
                                </label>
                                <label htmlFor="marketing" className={styles.checkbox}>
                                    <input
                                        id="marketing"
                                        checked={marketing}
                                        className={styles.checkboxInput}
                                        onChange={() => setMarketing(!marketing)}
                                        type="checkbox"
                                    />
                                    <span className={styles.checkmark} />
                                    <span>
                                        <FormattedMessage
                                            id="orderPage.marketing"
                                            defaultMessage="I agree for my personal data to be used in <link>marketing</link>"
                                            values={{ link: (text) => <Link to="/terms-of-service">{text}</Link> }}
                                        />
                                    </span>
                                </label>
                                <p className={styles.post}>
                                    <FormattedMessage
                                        id="orderPage.formPost"
                                        defaultMessage="Your service will come as soon as your order will be reviewed. It may take up to 48 hours (but usually it takes from 1 to 3 hours)."
                                    />
                                </p>
                                <div className={styles.inputGroup}>
                                    <label htmlFor="discount">
                                        <FormattedMessage
                                            id="orderPage.selectPaymentMethod"
                                            defaultMessage="Select payment method"
                                        />
                                    </label>
                                </div>
                                <label
                                    className={classNames(styles.radio, styles.flex, { [styles.active]: payment === 'PAYPAL' })}
                                    onClick={() => setPayment('PAYPAL')}
                                    disabled={process.env.GATSBY_PAYPAL === 'false'}
                                >
                                    <div className={classNames(styles.tick, { [styles.active]: payment === 'PAYPAL' })} />
                                    <img
                                        src={paypalImgPath}
                                        alt="Paypal logo"
                                        className={styles.paypal}
                                    />
                                    {' '}
                                    Paypal
                                    <input type="radio" />
                                </label>
                                <label
                                    className={classNames(styles.radio, styles.flex, { [styles.active]: payment === 'STRIPE' })}
                                    onClick={() => setPayment('STRIPE')}
                                    disabled={process.env.GATSBY_STRIPE === 'false'}
                                >
                                    <div className={classNames(styles.tick, { [styles.active]: payment === 'STRIPE' })} />
                                    <img
                                        src={stripeImgPath}
                                        alt="Stripe logo"
                                        className={styles.stripe}
                                    />
                                    <input type="radio" />
                                </label>
                                <button
                                    className={classNames(styles.submitButton, {
                                        [styles.disabled]: !terms || isLoading,
                                    })}
                                    type="submit"
                                    disabled={!terms || isLoading}
                                >
                                    <FormattedMessage
                                        id="orderPage.continue"
                                        defaultMessage="Continue"
                                    />
                                </button>
                            </form>
                        </div>
                    </div>
                ) : (
                    <>
                        <form className={styles.grid} onSubmit={handleSubmit}>
                            <div>
                                <h4 className={styles.title}>
                                    <FormattedMessage
                                        id="orderPage.selectedMedia"
                                        defaultMessage="1. Selected media platform"
                                    />
                                </h4>
                                <div className={styles.df}>
                                    <div className={styles.selected}>
                                        <ProductLogo type={name} />
                                        {getServiceName(name)}
                                    </div>
                                    <div
                                        className={styles.select}
                                        onClick={() => dispatch({
                                            type: 'TOGGLE_MODAL',
                                            payload: <ChangeMediaModal />,
                                        })}
                                    >
                                        <FormattedMessage
                                            id="orderPage.changePlatform"
                                            defaultMessage="Change platform"
                                        />
                                    </div>
                                </div>
                                <h4 className={styles.title}>
                                    <FormattedMessage
                                        id="orderPage.selectService"
                                        defaultMessage="2. Select service"
                                    />
                                </h4>
                                {serviceData.map(
                                    ({
                                        index,
                                        name: productName,
                                        id: productId,
                                        descriptionTitle,
                                    }) => (
                                        <label
                                            htmlFor={productId}
                                            key={`${productId}-${index}`}
                                            className={classNames(styles.radio, {
                                                [styles.active]: radio === parseInt(productId),
                                            })}
                                            onClick={() => setRadio(parseInt(productId))}
                                        >
                                            <div
                                                className={classNames(styles.tick, {
                                                    [styles.active]: radio === parseInt(productId),
                                                })}
                                            />
                                            {`${getServiceName(name)} ${productName} ${
                                                descriptionTitle ? `(${descriptionTitle})` : ''
                                            }`}
                                            <input type="radio" name="radio" id={productId} />
                                        </label>
                                    ),
                                )}
                                {!isNaN(price) && (
                                    <>
                                        <h4 className={classNames(styles.title, styles.mt35)}>
                                            <FormattedMessage
                                                id="orderPage.selectAmount"
                                                defaultMessage="3. Select the amount"
                                            />
                                        </h4>
                                        {serviceData?.length > 0 && (
                                            <div className={styles.slider}>
                                                <ul>
                                                    <li
                                                        onClick={() => setRangeValue(
                                                            serviceData.find(
                                                                (o) => o.id === radio,
                                                            )?.minRange,
                                                        )}
                                                    >
                                                        {
                                                            serviceData.find((o) => o.id === radio)
                                                                ?.minRange
                                                        }
                                                    </li>
                                                    <li
                                                        onClick={() => setRangeValue(
                                                            serviceData.find(
                                                                (o) => o.id === radio,
                                                            )?.maxRange
																	- serviceData.find(
																	    (o) => o.id === radio,
																	)?.maxRange
																		* (75 / 100),
                                                        )}
                                                    >
                                                        {serviceData.find((o) => o.id === radio)
                                                            ?.maxRange
															- serviceData.find((o) => o.id === radio)
															    ?.maxRange
																* (75 / 100)}
                                                    </li>
                                                    <li
                                                        onClick={() => setRangeValue(
                                                            serviceData.find(
                                                                (o) => o.id === radio,
                                                            )?.maxRange
																	- serviceData.find(
																	    (o) => o.id === radio,
																	)?.maxRange
																		* (50 / 100),
                                                        )}
                                                    >
                                                        {serviceData.find((o) => o.id === radio)
                                                            ?.maxRange
															- serviceData.find((o) => o.id === radio)
															    ?.maxRange
																* (50 / 100)}
                                                    </li>
                                                    <li
                                                        onClick={() => setRangeValue(
                                                            serviceData.find(
                                                                (o) => o.id === radio,
                                                            )?.maxRange
																	- serviceData.find(
																	    (o) => o.id === radio,
																	)?.maxRange
																		* (25 / 100),
                                                        )}
                                                    >
                                                        {serviceData.find((o) => o.id === radio)
                                                            ?.maxRange
															- serviceData.find((o) => o.id === radio)
															    ?.maxRange
																* (25 / 100)}
                                                    </li>
                                                    <li
                                                        onClick={() => setRangeValue(
                                                            serviceData.find(
                                                                (o) => o.id === radio,
                                                            )?.maxRange,
                                                        )}
                                                    >
                                                        {
                                                            serviceData.find((o) => o.id === radio)
                                                                ?.maxRange
                                                        }
                                                    </li>
                                                </ul>
                                                {serviceData.length > 0 && (
                                                    <InputRange
                                                        minValue={
                                                            serviceData.find((o) => o.id === radio)
                                                                ?.minRange
                                                        }
                                                        maxValue={
                                                            serviceData.find((o) => o.id === radio)
                                                                ?.maxRange
                                                        }
                                                        step={100}
                                                        value={rangeValue}
                                                        onChange={(value) => setRangeValue(value)}
                                                    />
                                                )}
                                            </div>
                                        )}
                                    </>
                                )}
                            </div>
                            <div>
                                <OrderSummary
                                    isNext
                                    name={name}
                                    serviceName={
                                        serverData.filter(
                                            ({ id: productId }) => productId === radio,
                                        )[0]?.name
                                    }
                                    amount={rangeValue}
                                    price={price}
                                    align="right"
                                />
                            </div>
                        </form>
                        <Post title={messages.title}>
                            <FormattedMessage
                                {...messages[`important${name.toUpperCase()}`]}
                                values={{
                                    br: <br />,
                                    h1: (text) => <h1>{text}</h1>,
                                    h2: (text) => <h2>{text}</h2>,
                                    h3: (text) => <h3>{text}</h3>,
                                    h4: (text) => <h4>{text}</h4>,
                                    h5: (text) => <h5>{text}</h5>,
                                    h6: (text) => <h6>{text}</h6>,
                                    b: (text) => <b>{text}</b>,
                                }}
                            />
                        </Post>
                    </>
                )}
            </div>
        </>
    );
};

export default OrderPage;
