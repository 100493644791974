/* eslint-disable no-restricted-globals */
import classNames from 'classnames';
import { defineMessages, FormattedMessage } from 'react-intl';
import Button, { ButtonStyle, ButtonType } from '../Button/Button';
import { getServiceName } from '../../helpers/helpers';
import * as styles from './OrderSummary.module.scss';

const messages = defineMessages({
    button: {
        id: 'orderSummary.button',
        defaultMessage: 'Continue to payment',
    },
});

const OrderSummary = ({
    name, serviceName, amount, price, align, isNext,
}) => (
    <div className={styles.wrapper}>
        <div className={classNames(styles.container, { [styles.right]: align === 'right' })}>
            <h4><FormattedMessage id="orderSummary.title" defaultMessage="Order summary" /></h4>
            <ul>
                {name && (
                    <li>
                        <span><FormattedMessage id="orderSummary.platform" defaultMessage="Platform" /></span>
                        {getServiceName(name)}
                    </li>
                )}
                {serviceName && (
                    <li>
                        <span><FormattedMessage id="orderSummary.service" defaultMessage="Service" /></span>
                        {serviceName}
                    </li>
                )}
                {!isNaN(amount) && (
                    <li>
                        <span><FormattedMessage id="orderSummary.amount" defaultMessage="Amount" /></span>
                        {amount}
                    </li>
                )}
                {!isNaN(price) && (
                    <li>
                        <span><FormattedMessage id="orderSummary.price" defaultMessage="Price" /></span>
                        {price.toFixed(2)}
                        {' '}
                        USD
                    </li>
                )}
            </ul>
        </div>
        {isNext && !isNaN(price) && !isNaN(amount) && serviceName && name && (
            <Button
                type={ButtonType.SUBMIT}
                style={ButtonStyle.BLUE}
                text={messages.button}
                className={styles.button}
            />
        )}
    </div>
);

export default OrderSummary;
