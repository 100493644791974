import { useState, useEffect, useContext } from 'react';
import { Link } from 'gatsby';
import { Link as RouterLink } from '@reach/router';
import axios from 'axios';
import { FormattedMessage } from 'react-intl';
import useWindowWidth from '../../../hooks/useWindowWidth';
import { Context } from '../../../store/Provider';
import * as styles from './ChangeMediaModal.module.scss';
import { getServiceName } from '../../../helpers/helpers';
import ProductLogo from '../../ProductLogo/ProductLogo';

const ChangeMediaModal = () => {
    const { dispatch } = useContext(Context);
    const [list, setList] = useState([]);
    const isMobile = useWindowWidth() < 992;
    const urlData = window?.location.pathname.split('/');
    const serviceName = urlData[urlData.length - 2];

    const getList = () => {
        axios.get(`${process.env.GATSBY_API_URL}/api/service/category`).then(({ data }) => setList(data)).catch((e) => console.log(e));
    };

    useEffect(() => {
        getList();
    }, []);
    return (
        <div className={styles.container}>
            <h4 className={styles.title}>
                <FormattedMessage id="changeMedia.title" defaultMessage="Change media platform" />
            </h4>
            <ul className={styles.list}>
                {list.length > 0 && list.map(({ name }) => (
                    <li key={name}>
                        {isMobile ? (
                            <RouterLink className={styles.product} to={`/order/${name.toLowerCase()}/${serviceName}/1`} onClick={() => dispatch({ type: 'TOGGLE_MODAL' })}>
                                <ProductLogo type={name} />
                                {getServiceName(name)}
                            </RouterLink>
                        ) : (
                            <Link className={styles.product} to={`/order/${name.toLowerCase()}/${serviceName}/1`} onClick={() => dispatch({ type: 'TOGGLE_MODAL' })}>
                                <ProductLogo type={name} />
                                {getServiceName(name)}
                            </Link>
                        )}

                    </li>
                ))}
            </ul>
        </div>
    );
};

export default ChangeMediaModal;
