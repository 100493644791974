import { Router } from '@reach/router';
import OrderPage from '../../pages/OrderPage/OrderPage';
import Layout from '../layout';

const OrderRouter = ({
    serverData, name, service, id,
}) => (
    <Layout>
        <Router>
            <OrderPage
                serverData={serverData}
                name={name}
                service={service}
                id={id}
                path="/order/:name/:service/:id?"
            />
        </Router>
    </Layout>
);

export default OrderRouter;

export async function getServerData({ params }) {
    const { name, service, id } = params;
    try {
        const res = await fetch(`${process.env.GATSBY_API_URL}/api/service?category=${name}`);
        if (!res.ok) {
            throw new Error('Response failed');
        }
        return {
            props: await res.json(),
            name,
            service,
            id,
        };
    } catch (error) {
        return {
            status: 500,
            headers: {},
            props: {},
        };
    }
}
