// extracted by mini-css-extract-plugin
export var active = "OrderPage-module--active--g9SA0";
export var apply = "OrderPage-module--apply--6B9sH";
export var checkbox = "OrderPage-module--checkbox--8IPYA";
export var checkboxInput = "OrderPage-module--checkboxInput--60Ap7";
export var checkmark = "OrderPage-module--checkmark--1zlmn";
export var container = "OrderPage-module--container--LPwq-";
export var df = "OrderPage-module--df--B8P2f";
export var disabled = "OrderPage-module--disabled--gZnK7";
export var discount = "OrderPage-module--discount--p0Ad0";
export var error = "OrderPage-module--error--I32AC";
export var errorInput = "OrderPage-module--errorInput--PEyP1";
export var flex = "OrderPage-module--flex--wl5Ov";
export var form = "OrderPage-module--form--vpGd7";
export var formTitle = "OrderPage-module--formTitle--kpcGV";
export var grid = "OrderPage-module--grid--sS9aU";
export var icon = "OrderPage-module--icon--UzBK7";
export var inputGroup = "OrderPage-module--inputGroup--7Ad0f";
export var mt35 = "OrderPage-module--mt35--Qs4cI";
export var paypal = "OrderPage-module--paypal--TN-wZ";
export var post = "OrderPage-module--post--pTfKF";
export var productHead = "OrderPage-module--productHead--+hnP7";
export var radio = "OrderPage-module--radio--8zSRA";
export var select = "OrderPage-module--select--eklW+";
export var selected = "OrderPage-module--selected--uQgad";
export var slider = "OrderPage-module--slider--jTEbr";
export var stripe = "OrderPage-module--stripe--thD0j";
export var submitButton = "OrderPage-module--submitButton--rGEEw";
export var tick = "OrderPage-module--tick--12sZ1";
export var title = "OrderPage-module--title--oHsPA";